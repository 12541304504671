import React from 'react'
import Login from './admin/Login'
import CompanyList from './admin/CompanyList'
import NewCompany from './admin/NewCompany'
import CompanyDetails from './admin/CompanyDetails'
import useAdmin from 'myHooks/useAdmin'
import './admin/_style/Admin.css'

import { adminScreens } from 'myUtils/constants'

const {
  COMPANIES,
  LOGIN,
  COMPANY,
  NEW_COMPANY
} = adminScreens

const Admin = () => {
  const {
    screen,
    companies,
    setScreen,
    getCompanies,
    viewCompany,
    navigateToNewCompany,
    navigateToCompanies,
    addCompany,
    deleteCompany,
    selectedCompany
  } = useAdmin()

  return (
    <div className='volo-Admin'>
      {screen === LOGIN && <Login setScreen={setScreen} />}
      {screen === COMPANIES && <CompanyList viewCompany={viewCompany} companies={companies} getCompanies={getCompanies} deleteCompany={deleteCompany} />}
      {screen === COMPANY && <CompanyDetails updateCompany={addCompany} selectedCompany={selectedCompany} />}
      {screen === NEW_COMPANY && <NewCompany createCompany={addCompany} />}
      {![COMPANIES, LOGIN].includes(screen) && <button onClick={navigateToCompanies}>BACK</button>}
      {[COMPANIES].includes(screen) && <button onClick={navigateToNewCompany}>NEW COMPANY</button>}
    </div>
  )
}

export default Admin
