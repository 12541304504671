import { useEffect, useState, useCallback } from 'react'
import moment from 'moment'
import numeral from 'numeral'
import { stages, flightOptions } from 'myUtils/constants'
import api from 'myUtils/api'

const {
  SELECT_CHARTER,
  REVIEW_QUOTE,
  PASSENGER_DETAILS
} = stages

const useQuote = () => {
  const [quotes, setQuotes] = useState(null)
  const [selectedQuote, setSelectedQuote] = useState(null)
  const [stage, setStage] = useState(SELECT_CHARTER)
  const [trip, setTrip] = useState(null)
  const [passengers, setPassengers] = useState(null)
  const [logo, setLogo] = useState(null)
  const [charterName, setCharterName] = useState(null)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [distance, setDistance] = useState(null)
  const [price, setPrice] = useState(null)
  const [pricePerPassenger, setPricePerPassenger] = useState(null)
  const [duration, setDuration] = useState(null)
  const [departDate, setDepartDate] = useState(null)
  const [returnDate, setReturnDate] = useState(null)
  const [optional, setOptional] = useState(null)
  const [firstName, setFirstName] = useState(null)
  const [lastName, setLastName] = useState(null)
  const [email, setEmail] = useState(null)
  const [phone, setPhone] = useState(null)
  const [contactPreference, setContactPreference] = useState(null)
  const [charterID, setCharterID] = useState(null)
  const [error, setError] = useState(false)
  const [tokenEvent, setTokenEvent] = useState(null)
  const [stripe] = useState(window.Stripe(process.env.REACT_APP_STRIPE_KEY))

  const setPassengerDetails = useCallback((firstName, lastName, email, phone, preference) => {
    setFirstName(firstName)
    setLastName(lastName)
    setEmail(email)
    setPhone(phone)
    setContactPreference(preference)
  }, [])

  const saveQuote = useCallback(async token => {
    if (firstName && email) {
      try {
        const res = await api.saveQuote({
          trip,
          token,
          passengers,
          charterName,
          from,
          to,
          distance,
          price,
          pricePerPassenger,
          duration,
          departDate,
          returnDate,
          optional,
          firstName,
          lastName,
          email,
          phone,
          origin: window.location.origin,
          charterID,
          contactPreference
        })
        return res
      } catch (err) {
        setError(true)
        return null
      }
    }
    return null
  }, [
    trip,
    passengers,
    charterName,
    from,
    to,
    distance,
    price,
    pricePerPassenger,
    duration,
    departDate,
    returnDate,
    optional,
    firstName,
    lastName,
    email,
    phone,
    charterID,
    contactPreference
  ])

  const postCardPay = useCallback(async () => {
    const res = await saveQuote()
    if (res) {
      const { stripeID } = res
      stripe.redirectToCheckout({ sessionId: stripeID })
    }
  }, [saveQuote, stripe])

  const dismissError = useCallback(() => setError(false), [])

  const getQuotes = useCallback((fromID, toID, passengers, trip, departDate, returnDate) => {
    const getQuotes = async (formID, toID, passengers) => {
      try {
        const quotesRes = await fetch(`/.netlify/functions/getQuote?ids=${fromID},${toID}&passengers=${passengers}&trip=${trip}`)
        const quotes = await quotesRes.json()
        if (quotes) setQuotes(quotes.filter(q => q))
        else setQuotes([])
      } catch (err) {
        setQuotes([])
      }
    }
    getQuotes(fromID, toID, passengers, trip)
    setTrip(flightOptions.find(p => String(p.value) === trip).label)
    setPassengers(passengers)
    if (departDate) setDepartDate(moment(departDate).format('MMM Do YYYY'))
    if (returnDate) setReturnDate(moment(returnDate).format('MMM Do YYYY'))
  }, [])

  const nextStage = useCallback(() => {
    if (stage === SELECT_CHARTER) setStage(REVIEW_QUOTE)
    if (stage === REVIEW_QUOTE) setStage(PASSENGER_DETAILS)
    window.scrollTo(0, 0)
  }, [stage])

  const previousStage = useCallback(() => {
    if (stage === REVIEW_QUOTE) setStage(SELECT_CHARTER)
    if (stage === PASSENGER_DETAILS) setStage(REVIEW_QUOTE)
    window.scrollTo(0, 0)
  }, [stage])

  const chooseQuote = useCallback(selectedQuote => {
    setSelectedQuote(selectedQuote)
    const {
      displayName,
      distance,
      duration,
      from,
      logo,
      price,
      pricePerPassenger,
      charterCompany,
      to
    } = quotes[selectedQuote]
    setCharterID(charterCompany)
    setCharterName(displayName)
    setDistance(numeral(distance).format('0,0') + ' miles')
    setDuration(duration)
    setFrom(from)
    setTo(to)
    setPrice('$' + numeral(price).format('0,0.00'))
    setPricePerPassenger('$' + numeral(pricePerPassenger).format('0,0.00'))
    setLogo(logo)
    nextStage()
  }, [nextStage, quotes])

  const mountApplePay = useCallback(async ref => {
    const paymentRequest = stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: `${trip} from ${from} to ${to}`,
        amount: 5000
      },
      requestPayerName: true,
      requestPayerEmail: true
    })
    var elements = stripe.elements()
    var prButton = elements.create('paymentRequestButton', {
      paymentRequest: paymentRequest
    })
    const hasApplePay = await paymentRequest.canMakePayment()
    if (hasApplePay) {
      ref.style.display = 'block'
      prButton.mount('.volo-Payment-ApplePay')
      paymentRequest.on('token', function (ev) {
        setTokenEvent(ev)
      })
    }
  }, [from, stripe, to, trip])

  useEffect(() => {
    const confirmPayment = async () => {
      const res = await saveQuote(tokenEvent.token)
      if (res) {
        const key = res.key
        tokenEvent.complete('success')
        window.location.href = `/confirmation?session_id=${key}`
      } else {
        tokenEvent.complete('fail')
      }
    }
    if (tokenEvent) confirmPayment()
  }, [saveQuote, stripe.charges, tokenEvent])

  return {
    quotes,
    stage,
    nextStage,
    previousStage,
    selectedQuote,
    trip,
    chooseQuote,
    passengers,
    getQuotes,

    mountApplePay,
    error,
    dismissError,
    saveQuote,
    setPassengerDetails,
    optional,
    setOptional,
    departDate,
    returnDate,
    charterName,
    distance,
    duration,
    from,
    logo,
    price,
    pricePerPassenger,
    to,
    firstName,
    lastName,
    email,
    phone,
    contactPreference,
    postCardPay
  }
}

export default useQuote
