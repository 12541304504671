import React from 'react'
import Proptypes from 'prop-types'
import './_style/ErrorDialog.css'

const ErrorDialog = ({ message, dismissError }) => (
  <div className='volo-ErrorDialog'>
    <div className='volo-ErrorDialog-container'>
      <div className='volo-ErrorDialog-title'>Error</div>
      <div className='volo-ErrorDialog-message'>{message}</div>
      <button onClick={dismissError} className='volo-ErrorDialog-button'>Close</button>
    </div>
  </div>
)

ErrorDialog.propTypes = {
  message: Proptypes.string,
  dismissError: Proptypes.func.isRequired
}

export default ErrorDialog
