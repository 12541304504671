export const adminScreens = {
  COMPANIES: 'COMPANIES',
  COMPANY: 'COMPANY',
  LOGIN: 'LOGIN',
  NEW_COMPANY: 'NEW_COMPANY'
}

export const flightOptions = [
  { value: 2, label: 'Roundtrip' },
  { value: 1, label: 'One Way' }
]

export const passengerOptions = [
  { value: 1, label: '1 Passenger' },
  { value: 2, label: '2 Passengers' },
  { value: 3, label: '3 Passengers' },
  { value: 4, label: '4 Passengers' },
  { value: 5, label: '5 Passengers' },
  { value: 6, label: '6 Passengers' },
  { value: 7, label: '7 Passengers' },
  { value: 8, label: '8 Passengers' },
  { value: 9, label: '9 Passengers' },
  { value: 10, label: '10 Passengers' },
  { value: 11, label: '11 Passengers' },
  { value: 12, label: '12 Passengers' },
  { value: 13, label: '13 Passengers' },
  { value: 14, label: '14 Passengers' },
  { value: 15, label: '14+ Passengers' }
]

export const stages = {
  SELECT_CHARTER: 'SELECT_CHARTER',
  REVIEW_QUOTE: 'REVIEW_QUOTE',
  PASSENGER_DETAILS: 'PASSENGER_DETAILS'
}
