import React from 'react'
import DatePicker from 'react-datepicker'
import PropTypes from 'prop-types'
import 'react-datepicker/dist/react-datepicker.css'
import './_style/DateSelect.css'

const DateSelect = ({ showReturn, setDepartDate, setReturnDate, departDate, returnDate }) => {
  return (
    <div className='volo-DateSelect'>
      <div className='volo-DateSelect-text'>leaving on</div>
      <DatePicker
        placeholderText='Any Date'
        selected={departDate}
        onChange={setDepartDate}
        minDate={new Date()}
      />
      {showReturn && <div className='volo-DateSelect-text'>returning on</div>}
      {showReturn &&
        <DatePicker
          placeholderText='Any Date'
          selected={returnDate}
          onChange={setReturnDate}
          minDate={departDate || new Date()}
        />}
    </div>
  )
}

DateSelect.propTypes = {
  setDepartDate: PropTypes.func,
  setReturnDate: PropTypes.func,
  departDate: PropTypes.string,
  returnDate: PropTypes.string,
  showReturn: PropTypes.bool
}

export default DateSelect
