import React, { useMemo, useCallback, useEffect, useRef, useState } from 'react'
import {
  Tooltip
} from 'react-tippy'
import 'react-tippy/dist/tippy.css'
import Proptypes from 'prop-types'
import Payment from './passengerDetails/Payment'
import './_style/PassengerDetails.css'

const PassengerDetails = ({
  mountApplePay,
  setPassengerDetails,
  postCardPay,
  firstName,
  lastName,
  email,
  phone,
  contactPreference
}) => {
  const [preference, setPreference] = useState(contactPreference)
  const [displayError, setDisplayError] = useState(false)
  const firstNameRef = useRef(null)
  const lastNameRef = useRef(null)
  const emailRef = useRef(null)
  const phoneRef = useRef(null)

  const saveDetails = useCallback(() => {
    if (firstNameRef && lastNameRef && emailRef && phoneRef) {
      const firstName = firstNameRef.current.value
      const lastName = lastNameRef.current.value
      const email = emailRef.current.value
      const phone = phoneRef.current.value
      setPassengerDetails(firstName, lastName, email, phone, preference)
    }
  }, [preference, setPassengerDetails])

  const onSave = useCallback(() => {
    setDisplayError(true)
    postCardPay()
  }, [postCardPay])

  useEffect(() => {
    saveDetails()
  }, [preference, saveDetails])

  const showError = useCallback(ref => {
    if (ref && ref.current) {
      const { value } = ref.current
      if (displayError && value === '') return ''
    }
    return 'none'
  }, [displayError])

  return useMemo(() => (
    <div className='volo-PassengerDetails'>
      <div className='volo-PassengerDetails-title'>Passenger Details</div>
      <div className='volo-PassengerDetails-inputs'>
        <div className='volo-PassengerDetails-input-container'>
          <div className='volo-PassengerDetails-header'>FIRST NAME</div>
          <input
            type='text'
            value={firstName || ''}
            className='volo-PassengerDetails-input'
            placeholder='e.g. Dan'
            name='fname'
            ref={firstNameRef}
            onChange={saveDetails}
          />
          <div style={{ display: showError(firstNameRef) }} className='volo-PassengerDetails-error'>Please enter first name</div>
        </div>
        <div className='volo-PassengerDetails-input-container'>
          <div className='volo-PassengerDetails-header'>LAST NAME</div>
          <input
            value={lastName || ''}
            type='text'
            className='volo-PassengerDetails-input'
            placeholder='e.g. Voloson'
            ref={lastNameRef}
            onChange={saveDetails}
            name='lname'
          />
          <div style={{ display: showError(lastNameRef) }} className='volo-PassengerDetails-error'>Please enter last name</div>
        </div>
        <div className='volo-PassengerDetails-input-container'>
          <div className='volo-PassengerDetails-header'>EMAIL ADDRESS</div>
          <input
            value={email || ''}
            type='email'
            className='volo-PassengerDetails-input'
            placeholder='e.g. dan@flyvolo.co'
            name='email'
            ref={emailRef}
            onChange={saveDetails}
          />
          <div style={{ display: showError(emailRef) }} className='volo-PassengerDetails-error'>Please enter email</div>
        </div>
        <div className='volo-PassengerDetails-input-container'>
          <div className='volo-PassengerDetails-header'>PHONE NUMBER</div>
          <input
            value={phone || ''}
            type='tel'
            className='volo-PassengerDetails-input'
            placeholder='e.g. (525) 542-4646'
            name='tel'
            ref={phoneRef}
            onChange={saveDetails}
          />
          <div style={{ display: showError(phoneRef) }} className='volo-PassengerDetails-error'>Please enter phone number</div>
        </div>
      </div>
      <div className='volo-PassengerDetails-title'>Contact Preferences</div>
      <div className='volo-PassengerDetails-preferences'>
        {['Email', 'Phone'].map((d, id) => (
          <div key={id} onClick={() => setPreference(d)} className={`volo-PassengerDetails-preference${preference === d ? '-selected' : ''}`}>{d}</div>
        ))}
      </div>
      <div className='volo-PassengerDetails-title'>Payment Details</div>
      <div className='volo-PassengerDetails-subtitle'>To finish reserving this flight, we require a $50 USD refundable fee.
        <div className='volo-PassengerDetails-tooltip'>
          <Tooltip
            title='We require a $50 dollar fee to ensure you are serious about booking'
            position='top'
            followCursor
          >&nbsp;
          Learn more
          </Tooltip>
        </div>
      </div>
      <Payment mountApplePay={mountApplePay} saveQuote={onSave} enabled={Boolean(email && firstName)} />
    </div>
  ), [email, firstName, lastName, mountApplePay, onSave, phone, preference, saveDetails, showError])
}

PassengerDetails.propTypes = {
  mountApplePay: Proptypes.func,
  setPassengerDetails: Proptypes.func,
  postCardPay: Proptypes.func,
  firstName: Proptypes.string,
  lastName: Proptypes.string,
  email: Proptypes.string,
  phone: Proptypes.string,
  contactPreference: Proptypes.string
}

export default PassengerDetails
