import React from 'react'
import PropTypes from 'prop-types'
import LocationSelect from './locations/LocationSelect'
import './locations/_style/Locations.css'

const Locations = ({ setFrom, setTo }) => {
  return (
    <div className='volo-Locations'>
      <LocationSelect origin setLocation={setFrom} />
      <LocationSelect origin={false} setLocation={setTo} />
    </div>
  )
}

Locations.propTypes = {
  setFrom: PropTypes.func.isRequired,
  setTo: PropTypes.func.isRequired
}

export default Locations
