import React from 'react'
// import { FaWifi, FaStar } from 'react-icons/fa'
import numeral from 'numeral'
// import { MdLocationOn, MdFlightTakeoff } from 'react-icons/md'
import PropTypes from 'prop-types'
import './_style/CharterOption.css'

const CharterOption = ({
  price,
  pricePerPassenger,
  displayName,
  logo,
  selectedQuote,
  setSelectedQuote,
  quote,
  from,
  distance
}) =>
  (
    <div className='volo-CharterOption'>
      <div className='volo-CharterOption-values'>
        <div className='volo-CharterOption-name'>
          <img className='volo-CharterOption-logo' src={logo} alt='logo' />
          <div>
            <div className='volo-CharterOption-title'>VERIFIED VOLO PARTNER</div>
            <div className='volo-CharterOption-value'>{displayName}</div>
          </div>
        </div>
      </div>
      <div className='volo-CharterOption-values'>
        <div className='volo-CharterOption-price'>
          <div>
            <div className='volo-CharterOption-title'>ESTIMATED AIRCRAFT COST</div>
            <div className='volo-CharterOption-value'>${numeral(price).format('0,0.00')}</div>
            <div className='volo-CharterOption-priceper'>${numeral(pricePerPassenger).format('0,0.00')} per passenger</div>
          </div>
        </div>
      </div>
      <div onClick={() => setSelectedQuote(quote)} className={`volo-CharterOption-select${selectedQuote === quote ? 'ed' : ''}`}>Select{selectedQuote === quote ? 'ed' : ''}</div>
    </div>
  )

CharterOption.propTypes = {
  price: PropTypes.string,
  pricePerPassenger: PropTypes.string,
  from: PropTypes.string,
  distance: PropTypes.number,
  selectedQuote: PropTypes.number,
  setSelectedQuote: PropTypes.func.isRequired,
  quote: PropTypes.number,
  displayName: PropTypes.string,
  logo: PropTypes.string
}

export default React.memo(CharterOption)
