/*
 * Warning: this code was made in a rush and
 * should be refactored on the first opportunity
 */
import React, { useState, useRef } from 'react'
import Dropzone from 'react-dropzone'
import Proptypes from 'prop-types'

const renderObject = (array, ref, newAircraftsNumber, deletedAircrafts, setDeletedAircrafts) => {
  const deleteAircraft = id => {
    setDeletedAircrafts([...deletedAircrafts, id])
  }
  const aircraftKeys = ['aircraftLocation', 'aircraftModel', 'aircraftPricePerHour', 'aircraftRadius', 'aircraftSpeed', 'quantity', 'seats', 'wifi']
  const arrayLength = array ? array.length : 0
  return (
    <form ref={ref}>
      <table>
        <tbody>
          <tr>
            {aircraftKeys && aircraftKeys.map(k => (<th key={k}>{k}</th>))}
          </tr>
          {array && array.map((row, j) => {
            if (deletedAircrafts.includes(j)) return null
            return (
              <tr key={j}>
                {Object.keys(row).map((k, i) => (
                  <td key={i}><input size='15' id={j} name={k} defaultValue={row[k]} /></td>
                ))}
                <td onClick={() => deleteAircraft(j)}><div id='delete'>DELETE</div></td>
              </tr>
            )
          })}
          {newAircraftsNumber > 0 && new Array(newAircraftsNumber).fill(0).map((row, j) => {
            if (deletedAircrafts.includes(j + arrayLength)) return null
            return (
              <tr key={j + arrayLength}>
                {aircraftKeys.map((k, i) => (
                  <td key={i}><input size='15' id={j + arrayLength} name={k} /></td>
                ))}
                <td onClick={() => deleteAircraft(j + arrayLength)}><div id='delete'>DELETE</div></td>
              </tr>)
          })}
        </tbody>
      </table>
    </form>
  )
}

const CompanyDetails = ({ updateCompany, selectedCompany }) => {
  const [newLogo, setNewLogo] = useState(null)
  const [deletedAircrafts, setDeletedAircrafts] = useState([])
  const [newAircraftsNumber, setNewAircraftsNumber] = useState(0)
  const aircrafts = selectedCompany.aircrafts
  const formRef = useRef(null)
  const aircraftsRef = useRef(null)
  const saveCompany = () => {
    const newCompany = {}
    formRef.current.childNodes.forEach(cur => {
      if (cur && cur.children.length) {
        const el = cur.children[0]
        const value = el.name === 'logo' ? el.src : el.value
        newCompany[el.name] = value
      }
    })
    const newAircrafts = {}
    aircraftsRef.current.childNodes.forEach((cur, i) => {
      cur.children[0].childNodes.forEach((cur, i) => {
        if (i === 0) return
        cur.childNodes.forEach(cur => {
          const el = cur.childNodes[0]
          try {
            newAircrafts[el.id][el.name] = el.value
          } catch (err) {
            const newAircraft = {}
            newAircraft[el.name] = el.value
            newAircrafts[el.id] = newAircraft
          }
        })
      })
    })
    const aircrafts = Object.keys(newAircrafts).filter(k => k !== 'delete').map(k => newAircrafts[k])
    const fullCompany = { ...newCompany, aircrafts }
    updateCompany(fullCompany)
  }

  const onDrop = files => {
    const file = files[0]
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setNewLogo(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  return (
    <div><h1>Edit Company</h1>
      <form ref={formRef}>
        {Object.keys(selectedCompany).map(k => {
          if (k === 'aircrafts') return null
          if (k === 'logo') {
            return (
              <div key={k}>{k}:
                <img
                  alt='logo'
                  name='logo'
                  src={newLogo || selectedCompany[k]}
                  style={{ height: '10vh', objectFit: 'scale-down' }}
                />
                <Dropzone onDrop={onDrop}>
                  {({ getRootProps, getInputProps }) => (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p>Drag 'n' drop some files here, or click to select files</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            )
          }
          return <div key={k}>{k}:<input name={k} defaultValue={selectedCompany[k]} /></div>
        })}
      </form>
      {renderObject(aircrafts, aircraftsRef, newAircraftsNumber, deletedAircrafts, setDeletedAircrafts)}
      <button onClick={saveCompany}>Save Company</button>
      <button onClick={() => setNewAircraftsNumber(newAircraftsNumber + 1)}>New Aircraft</button>
    </div>
  )
}

CompanyDetails.propTypes = {
  selectedCompany: Proptypes.object,
  updateCompany: Proptypes.func
}

export default CompanyDetails
