import { useState } from 'react'
import { adminScreens } from 'myUtils/constants'
import api from 'myUtils/api'

const {
  COMPANIES,
  LOGIN,
  COMPANY,
  NEW_COMPANY
} = adminScreens

const useAdmin = () => {
  const [screen, setScreen] = useState(COMPANIES)
  const [companies, setCompanies] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)

  const getCompanies = async () => {
    try {
      const companies = await api.getCompanies()
      setCompanies(companies)
    } catch (err) {
      localStorage.setItem('adminToken', '')
      setScreen(LOGIN)
      return null
    }
  }

  const viewCompany = (id) => {
    const selectedCompany = Object.assign({}, companies.companies[id])
    setSelectedCompany(selectedCompany)
    setScreen(COMPANY)
  }

  const navigateToCompanies = () => setScreen(COMPANIES)

  const navigateToNewCompany = () => setScreen(NEW_COMPANY)

  const addCompany = async company => {
    try {
      await api.addCompany(company)
      setScreen(COMPANIES)
    } catch (err) {
      localStorage.setItem('adminToken', '')
      setScreen(LOGIN)
    }
  }

  const deleteCompany = async id => {
    try {
      var r = window.confirm(`Delete ${id}?`)
      if (r === true) {
        await api.deleteCompany(id)
        await getCompanies()
      }
    } catch (err) {
      localStorage.setItem('adminToken', '')
      setScreen(LOGIN)
    }
  }

  return {
    screen,
    addCompany,
    deleteCompany,
    navigateToCompanies,
    navigateToNewCompany,
    companies,
    viewCompany,
    selectedCompany,
    getCompanies,
    setScreen
  }
}

export default useAdmin
