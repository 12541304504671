import React, { useRef } from 'react'
import { MdAirlineSeatReclineNormal, MdAttachMoney, MdLocationOn } from 'react-icons/md'
import { FaRegClock } from 'react-icons/fa'
import Proptypes from 'prop-types'
import './_style/CharterDetails.css'

const CharterDetails = ({
  duration,
  price,
  pricePerPassenger,
  distance,
  passengers,
  logo,
  charterName,
  from,
  to,
  optional,
  setOptional,
  departDate
}) => {
  const optionalRef = useRef(null)
  const addOptional = () => {
    if (optionalRef && optionalRef.current) {
      setOptional(optionalRef.current.value)
    }
  }
  return (
    <div className='volo-CharterDetails'>
      <div className='volo-CharterDetails-destination'><b>{from}</b> to <b>{to}</b> {departDate && 'on'} <b>{departDate || ''}</b></div>
      <div className='volo-CharterDetails-grid'>
        <div className='volo-CharterDetails-grid-detail'>
          <FaRegClock className='volo-CharterDetails-icon' />
          <div className='volo-CharterDetails-subtitle'>DURATION</div>
          <div className='volo-ChaterDetails-value'><b>{duration}</b></div>
        </div>
        <div className='volo-CharterDetails-grid-detail'>
          <MdLocationOn className='volo-CharterDetails-icon' />
          <div className='volo-CharterDetails-subtitle'>DISTANCE</div>
          <div className='volo-ChaterDetails-value'><b>{distance}</b></div>
        </div>
        <div className='volo-CharterDetails-grid-detail'>
          <MdAttachMoney className='volo-CharterDetails-icon' />
          <div className='volo-CharterDetails-subtitle'>ESTIMATED AIRCRAFT COST</div>
          <div className='volo-ChaterDetails-value'><b>{price}</b> ({pricePerPassenger} per passenger)</div>
        </div>
        <div className='volo-CharterDetails-grid-detail'>
          <MdAirlineSeatReclineNormal className='volo-CharterDetails-icon' />
          <div className='volo-CharterDetails-subtitle'>PASSENGERS</div>
          <div className='volo-ChaterDetails-value'><b>{passengers}</b></div>
        </div>
      </div>
      <div className='volo-CharterDetails-title'>Provided By</div>
      <div className='volo-CharterDetails-container'>
        <img alt='logo' className='volo-CharterDetails-logo' src={logo} />
        <div className='volo-CharterDetails-subtitle'>VERIFIED VOLO PARTNER</div>
        <div className='volo-ChaterDetails-value'><b>{charterName}</b></div>
      </div>
      <div className='volo-CharterDetails-title'>Additional Requests</div>
      <div className='volo-CharterDetails-container-options'>
        <div className='volo-CharterDetails-subtitle'><b>OPTIONAL</b></div>
        <textarea
          value={optional || ''}
          onChange={addOptional}
          className='volo-ChaterDetails-options'
          placeholder='Add any transportation, lodging, or food services requests'
          ref={optionalRef}
        />
      </div>
    </div>
  )
}

CharterDetails.propTypes = {
  duration: Proptypes.string,
  price: Proptypes.string,
  pricePerPassenger: Proptypes.string,
  distance: Proptypes.string,
  passengers: Proptypes.string,
  logo: Proptypes.string,
  charterName: Proptypes.string,
  from: Proptypes.string,
  to: Proptypes.string,
  departDate: Proptypes.string,
  optional: Proptypes.string,
  setOptional: Proptypes.func
}

export default React.memo(CharterDetails)
