import React from 'react'
import { MdAirlineSeatReclineNormal, MdAttachMoney, MdLocationOn, MdFlightTakeoff } from 'react-icons/md'
import Proptypes from 'prop-types'
import './_style/Summary.css'

const Summary = ({
  from,
  to,
  departDate,
  returnDate,
  duration,
  distance,
  price,
  pricePerPassenger,
  passengers,
  charterName,
  logo,
  optional,
  trip
}) => {
  return (
    <div className='volo-Summary'>
      <div className='volo-Summary-title'>Summary</div>
      <div className='volo-Summary-text'>{trip} flight from <b>{from}</b> to <b>{to}</b> {departDate && 'on'} <b>{departDate || ''}</b></div>

      <div className='volo-Summary-container'>
        <MdFlightTakeoff className='volo-Summary-icon' />
        <div className='volo-Summary-subtitle'>DURATION</div>
        <div className='volo-ChaterDetails-value'><b>{duration}</b></div>
      </div>
      <div className='volo-Summary-container'>
        <MdLocationOn className='volo-Summary-icon' />
        <div className='volo-Summary-subtitle'>DISTANCE</div>
        <div className='volo-ChaterDetails-value'><b>{distance}</b></div>
      </div>
      <div className='volo-Summary-container'>
        <MdAttachMoney className='volo-Summary-icon' />
        <div className='volo-Summary-subtitle'>ESTIMATED AIRCRAFT COST</div>
        <div className='volo-ChaterDetails-value'><b>{price}</b> ({pricePerPassenger} per passenger)</div>
      </div>
      <div className='volo-Summary-container'>
        <MdAirlineSeatReclineNormal className='volo-Summary-icon' />
        <div className='volo-Summary-subtitle'>PASSENGERS</div>
        <div className='volo-ChaterDetails-value'><b>{passengers}</b></div>
      </div>
      <div className='volo-Summary-container'>
        <img src={logo} alt='provider' className='volo-Summary-logo' />
        <div className='volo-Summary-subtitle'>PROVIDED BY</div>
        <div className='volo-ChaterDetails-value'><b>{charterName}</b></div>
      </div>
      <div className='volo-Summary-container-single'>
        <div className='volo-Summary-subtitle'>ADDITIONAL REQUESTS</div>
        <div className='volo-ChaterDetails-value'><b>{optional}</b></div>
      </div>
    </div>
  )
}

Summary.propTypes = {
  from: Proptypes.string,
  to: Proptypes.string,
  departDate: Proptypes.string,
  returnDate: Proptypes.string,
  duration: Proptypes.string,
  distance: Proptypes.string,
  price: Proptypes.string,
  pricePerPassenger: Proptypes.string,
  passengers: Proptypes.string,
  logo: Proptypes.string,
  trip: Proptypes.string,
  optional: Proptypes.string,
  charterName: Proptypes.string
}

export default React.memo(Summary)
