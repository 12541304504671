import React, { useCallback } from 'react'
import Header from 'myPages/Header'
import BookingTypeSelect from './home/BookingTypeSelect'
import DateSelect from './home/DateSelect'
import Locations from './home/Locations'
import useSearch from 'myHooks/useSearch'
import './home/_style/Home.css'

const Home = () => {
  const {
    trip,
    setTrip,
    passengers,
    setPassengers,
    departDate,
    setDepartDate,
    returnDate,
    setReturnDate,
    from,
    setFrom,
    to,
    setTo
  } = useSearch()

  const onSubmit = useCallback(() => {
    if (from && to) {
      const query = {}
      query.from = from.id
      query.to = to.id
      query.passengers = passengers.value
      query.trip = trip.value
      if (departDate) query.departDate = departDate.toISOString().split('T')[0]
      if (returnDate) query.returnDate = returnDate.toISOString().split('T')[0]
      let queryString = '?'
      Object.keys(query).forEach(k => {
        queryString += k + '=' + query[k] + '&'
      })
      window.location.href = `/quote${queryString}`
    }
  }, [departDate, from, passengers.value, returnDate, to, trip.value])

  const checkInputs = useCallback(() => {
    return to === null || from === null ? 'disabled' : ''
  }, [from, to])

  return (
    <div className='volo-Home'>
      <Header />
      <BookingTypeSelect trip={trip} passengers={passengers} setTrip={setTrip} setPassengers={setPassengers} />
      <Locations
        to={to}
        from={from}
        setTo={setTo}
        setFrom={setFrom}
      />
      <DateSelect
        showReturn={trip.value === 2}
        setDepartDate={setDepartDate}
        setReturnDate={setReturnDate}
        departDate={departDate}
        returnDate={returnDate}
      />
      <button onClick={onSubmit} className={`volo-Home-button ${checkInputs()}`}>Find Flights</button>
    </div>
  )
}

export default Home
