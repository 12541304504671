import React, { useEffect } from 'react'
import queryString from 'query-string'
import CharterOption from './selectCharter/CharterOption'
import Proptypes from 'prop-types'
import './selectCharter/_style/SelectCharter.css'

const SelectCharter = ({ quotes, selectedQuote, setSelectedQuote, getQuotes }) => {
  useEffect(() => {
    const { from, to, passengers, trip, departDate, returnDate } = queryString.parse(window.location.search)
    getQuotes(from, to, passengers, trip, departDate, returnDate)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMessage = () => {
    if (quotes === null) return ''
    const quotesNumber = Object.keys(quotes).length
    if (quotesNumber === 0) {
      return (
        <div>
          <div className='volo-SelectCharter-count'>Sorry, no immediate charter companies available.</div>
          <div className='volo-SelectCharter-count'>Try booking directly with one of our partners below:</div>
          <div className='volo-CharterOption'>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-name'>
                <img className='volo-CharterOption-logo' src='../justiceair.png' alt='logo' />
                <div>
                  <div className='volo-CharterOption-title'>VERIFIED VOLO PARTNER</div>
                  <div className='volo-CharterOption-value'>Justice Air</div>
                </div>
              </div>
            </div>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-price'>
                <div>
                  <div className='volo-CharterOption-title'>EMAIL</div>
                  <a href='mailto:charter@justiceair.com'><div className='volo-CharterOption-value'>charter@justiceair.com</div></a>
                </div>
              </div>
            </div>
            <a href='tel:631-465-0138'><div className='volo-CharterOption-select'>(631) 465-0138</div></a>
          </div>
          <div className='volo-CharterOption'>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-name'>
                <img className='volo-CharterOption-logo' src='../aircraftservicesgroup.jpg' alt='logo' />
                <div>
                  <div className='volo-CharterOption-title'>VERIFIED VOLO PARTNER</div>
                  <div className='volo-CharterOption-value'>Aircraft Services Group</div>
                </div>
              </div>
            </div>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-price'>
                <div>
                  <div className='volo-CharterOption-title'>EMAIL</div>
                  <a href='mailto:charter@jetasg.com'><div className='volo-CharterOption-value'>charter@jetasg.com</div></a>
                </div>
              </div>
            </div>
            <a href='tel:973-864-6200'><div className='volo-CharterOption-select'>(973) 864-6200</div></a>
          </div>

          <div className='volo-CharterOption'>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-name'>
                <img className='volo-CharterOption-logo' src='../nea.png' alt='logo' />
                <div>
                  <div className='volo-CharterOption-title'>VERIFIED VOLO PARTNER</div>
                  <div className='volo-CharterOption-value'>Northeastern Aviation Corp.</div>
                </div>
              </div>
            </div>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-price'>
                <div>
                  <div className='volo-CharterOption-title'>EMAIL</div>
                  <a href='mailto:charter@neajets.com'><div className='volo-CharterOption-value'>charter@neajets.com</div></a>
                </div>
              </div>
            </div>
            <a href='tel:800-234-0046'><div className='volo-CharterOption-select'>(800) 234-0046</div></a>
          </div>

          <div className='volo-CharterOption'>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-name'>
                <img className='volo-CharterOption-logo' src='../presidential.jpg' alt='logo' />
                <div>
                  <div className='volo-CharterOption-title'>VERIFIED VOLO PARTNER</div>
                  <div className='volo-CharterOption-value'>Presidential Aviation</div>
                </div>
              </div>
            </div>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-price'>
                <div>
                  <div className='volo-CharterOption-title'>EMAIL</div>
                  <a href='mailto:vkraft@presidential-aviation.com'><div className='volo-CharterOption-value'>vkraft@presidential-aviation.com</div></a>
                </div>
              </div>
            </div>
            <a href='tel:954-489-7172'><div className='volo-CharterOption-select'>(954) 489-7172</div></a>
          </div>

          <div className='volo-CharterOption'>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-name'>
                <img className='volo-CharterOption-logo' src='../aok.jpg' alt='logo' />
                <div>
                  <div className='volo-CharterOption-title'>VERIFIED VOLO PARTNER</div>
                  <div className='volo-CharterOption-value'>A-OK Jets</div>
                </div>
              </div>
            </div>
            <div className='volo-CharterOption-values'>
              <div className='volo-CharterOption-price'>
                <div>
                  <div className='volo-CharterOption-title'>EMAIL</div>
                  <a href='mailto:charter@aokjets.com'><div className='volo-CharterOption-value'>charter@aokjets.com</div></a>
                </div>
              </div>
            </div>
            <a href='tel:954-772-4693'><div className='volo-CharterOption-select'>(954) 772-4693</div></a>
          </div>
        </div>
      )
    }
    return quotesNumber + ' Available Charters'
  }

  return (
    <div className='volo-SelectCharter'>
      <div className='volo-SelectCharter-count'>{getMessage()}</div>
      <div>{quotes && quotes.map((quote, id) => {
        const { price, pricePerPassenger, displayName, logo, distance, from } = quote
        if (price && displayName) {
          return (
            <CharterOption
              key={id}
              quote={id}
              distance={distance}
              from={from}
              setSelectedQuote={setSelectedQuote}
              selectedQuote={selectedQuote}
              price={price}
              pricePerPassenger={pricePerPassenger}
              displayName={displayName}
              logo={logo}
            />
          )
        } else return null
      })}
      </div>
      <div className='volo-SelectCharter-Contact-message'>Would you like your charter company listed?
        <a className='volo-SelectCharter-Contact-link' href='mailto:contact@flyvolo.co'>Contact us</a>
      </div>
    </div>
  )
}

SelectCharter.propTypes = {
  quotes: Proptypes.array,
  selectedQuote: Proptypes.number,
  getQuotes: Proptypes.func,
  setSelectedQuote: Proptypes.func
}

export default React.memo(SelectCharter)
