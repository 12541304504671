import React from "react";
import "./footer/_style/Footer.css";

const Footer = () => {
  const navigateTo = (href) => {
    window.location.href = href;
  };

  return (
    <div className="volo-Footer">
      <div className="volo-Footer-copyright">
        Volo LLC © 2024 ·{" "}
        <div
          className="volo-Footer-link"
          onClick={() =>
            navigateTo(
              "https://app.termly.io/document/privacy-policy/87a8ed34-3904-4039-bdbe-0b53880deb19",
            )
          }
        >
          Privacy Policy
        </div>{" "}
        ·{" "}
        <div
          className="volo-Footer-link"
          onClick={() => navigateTo("mailto:contact@flyvolo.co")}
        >
          Email
        </div>{" "}
        ·{" "}
        <div
          className="volo-Footer-link"
          onClick={() => navigateTo("tel:8458438656")}
        >
          Phone
        </div>
      </div>
    </div>
  );
};

export default Footer;
