import 'core-js'
import './App.css'
import React from 'react'
import Home from 'myPages/Home'
import Footer from 'myPages/Footer'
import About from 'myPages/About'
import Quote from 'myPages/Quote'
import Admin from 'myPages/Admin'
import Confirmation from 'myPages/Confirmation'
import { useRoutes } from 'hookrouter'

const routes = {
  '/': () => <Home />,
  '/about': () => <About />,
  '/quote': () => <Quote />,
  '/confirmation': () => <Confirmation />,
  '/admin': () => <Admin />
}

const App = () => {
  const routeResult = useRoutes(routes)

  return (
    <div>
      {routeResult || <Home />}
      <Footer />
    </div>
  )
}

export default App
