import React from 'react'
import PropTypes from 'prop-types'
import Dropdown from 'react-dropdown'
import './_style/BookingTypeSelect.css'
import { flightOptions, passengerOptions } from 'myUtils/constants'

const BookingTypeSelect = ({ trip, passengers, setTrip, setPassengers }) => {
  return (
    <div className='volo-BookingTypeSelect'>
      <div className='volo-BookingTypeSelect-text'>I'd like to book a</div>
      <Dropdown
        className='volo-BookingTypeSelect-dropdown'
        onChange={setTrip}
        options={flightOptions}
        value={trip}
        placeholder='Select an option'
      />
      <div className='volo-BookingTypeSelect-text'>private flight for</div>
      <Dropdown
        className='volo-BookingTypeSelect-dropdown'
        onChange={setPassengers}
        options={passengerOptions}
        value={passengers}
      />
    </div>
  )
}

BookingTypeSelect.propTypes = {
  setTrip: PropTypes.func.isRequired,
  trip: PropTypes.object,
  passengers: PropTypes.object,
  setPassengers: PropTypes.func.isRequired
}

export default BookingTypeSelect
