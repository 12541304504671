import queryString from 'query-string'
import { useState } from 'react'

const useConfirmation = () => {
  const [trip, setTrip] = useState(null)
  const [charterName, setCharterName] = useState(null)
  const [distance, setDistance] = useState(null)
  const [duration, setDuration] = useState(null)
  const [price, setPrice] = useState(null)
  const [pricePerPassenger, setPricePerPassenger] = useState(null)
  const [from, setFrom] = useState(null)
  const [to, setTo] = useState(null)
  const [passengers, setPassengers] = useState(null)
  const [departDate, setDepartDate] = useState(null)
  const [logo, setLogo] = useState(null)
  const [charterEmail, setCharterEmail] = useState(null)

  const getQuote = () => {
    const getQuote = async () => {
      const { session_id: id } = queryString.parse(window.location.search)
      const res = await fetch(`/.netlify/functions/getConfirmQuote?id=${id}`)
      const quote = await res.json()
      if (quote) {
        const {
          trip,
          charterName,
          distance,
          duration,
          from,
          to,
          passengers,
          price,
          pricePerPassenger,
          logo,
          charterEmail,
          departDate
        } = quote
        setTrip(trip)
        setCharterName(charterName)
        setDistance(distance)
        setDuration(duration)
        setLogo(logo)
        setTo(to)
        setFrom(from)
        setPassengers(passengers)
        setPrice(price)
        setPricePerPassenger(pricePerPassenger)
        setCharterEmail(charterEmail)
        if (departDate) setDepartDate(departDate)
      }
    }
    getQuote()
  }

  return {
    getQuote,
    logo,
    trip,
    charterName,
    distance,
    duration,
    from,
    to,
    passengers,
    price,
    pricePerPassenger,
    departDate,
    charterEmail
  }
}

export default useConfirmation
