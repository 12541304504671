import * as firebase from 'firebase/app'
import 'firebase/auth'

const firebaseConfigDev = {
  apiKey: 'AIzaSyB7euyCfPraODF3m1qzzeY5osRuEF5uAIs',
  authDomain: 'fly-volo.firebaseapp.com',
  databaseURL: 'https://fly-volo.firebaseio.com',
  projectId: 'fly-volo',
  storageBucket: 'fly-volo.appspot.com',
  messagingSenderId: '847846856766',
  appId: '1:847846856766:web:cf7df1173c9273f279196f',
  measurementId: 'G-JQRJZTKN0J'
}

const firebaseConfigProd = {
  apiKey: 'AIzaSyBkLLAayVd7CQjhgEvwBmmbB1de7rL3Dac',
  authDomain: 'volo-prod.firebaseapp.com',
  databaseURL: 'https://volo-prod.firebaseio.com',
  projectId: 'volo-prod',
  storageBucket: 'volo-prod.appspot.com',
  messagingSenderId: '995616603960',
  appId: '1:995616603960:web:3d79ddefd57171579a0bcd'
}
firebase.initializeApp(process.env.REACT_APP_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev)
export const auth = firebase.auth

export default firebase
