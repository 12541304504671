import React, { useEffect, useState } from 'react'
import Proptypes from 'prop-types'

const CompanyList = ({ viewCompany, companies, getCompanies, deleteCompany }) => {
  const [companyList, setCompanyList] = useState([])
  useEffect(() => {
    getCompanies()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (companies && companies.companies) {
      const companyList = Object.keys(companies.companies).map(k => (
        { name: companies.companies[k].displayName, id: k }
      ))
      setCompanyList(companyList)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies])

  return (
    <div>
      <h1>Companies</h1>
      <table>
        <tbody>
          <tr>
            <th>Name</th>
            <th />
            <th />
          </tr>
          {companyList.map((cl, i) => (
            <tr key={i}>
              <td>{cl.name}</td>
              <td onClick={() => viewCompany(cl.id)}>Edit</td>
              <td onClick={() => deleteCompany(cl.id)}>Delete</td>
            </tr>

          ))}
        </tbody>
      </table>
    </div>
  )
}

CompanyList.propTypes = {
  companies: Proptypes.object,
  getCompanies: Proptypes.func,
  viewCompany: Proptypes.func,
  deleteCompany: Proptypes.func
}

export default CompanyList
