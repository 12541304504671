import React, { useState, useRef } from 'react'
import Proptypes from 'prop-types'
import Dropzone from 'react-dropzone'

const attributes = ['address', 'basesOfOperation', 'charterCompany', 'dateJoined', 'displayName', 'email', 'extendedSupport', 'logo', 'phone', 'satelliteBases', 'socialMedia']

const NewCompany = ({ createCompany }) => {
  const [logo, setLogo] = useState('')
  const ref = useRef(null)
  const onDrop = files => {
    const file = files[0]
    var reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function () {
      setLogo(reader.result)
    }
    reader.onerror = function (error) {
      console.log('Error: ', error)
    }
  }

  const saveCompany = () => {
    const company = { logo, aircrafts: [] }
    ref.current.childNodes.forEach(cn => {
      const cns = cn.childNodes

      if (cns.length > 2 && cns[2].tagName === 'INPUT') {
        const input = cns[2]
        company[input.name] = input.value
      }
    })
    createCompany(company)
  }
  return (
    <div ref={ref}>
      <h1>New Company</h1>
      {attributes.map((att, i) => {
        if (att === 'logo') {
          return (
            <div key='logo'>{att}: {<img src={logo} alt='logo' />}
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p>Drag 'n' drop some files here, or click to select files</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          )
        }
        return (
          <div key={i}>{att}: <input name={att} /></div>
        )
      })}
      <button onClick={saveCompany}>SAVE</button>
    </div>
  )
}

NewCompany.propTypes = {
  createCompany: Proptypes.func
}

export default NewCompany
