import { useState } from 'react'
import { flightOptions, passengerOptions } from 'myUtils/constants'

const useSearch = () => {
  const [trip, setTrip] = useState(flightOptions[0])
  const [passengers, setPassengers] = useState(passengerOptions[0])
  const [to, setTo] = useState(null)
  const [from, setFrom] = useState(null)
  const [returnDate, setReturnDate] = useState(null)
  const [departDate, setDepartDate] = useState(null)

  return {
    trip,
    setTrip,
    passengers,
    setPassengers,
    to,
    setTo,
    from,
    setFrom,
    returnDate,
    setReturnDate,
    departDate,
    setDepartDate
  }
}

export default useSearch
