import { auth } from 'myUtils/firebase'

const useLogin = (navigateToComanies) => {
  const loginUser = (email, password) => {
    const getIdToken = async (email, password) => {
      try {
        await auth().signInWithEmailAndPassword(email, password)
        const token = await auth().currentUser.getIdToken()
        localStorage.setItem('adminToken', token)
        navigateToComanies()
      } catch (err) {
        return null
      }
    }
    getIdToken(email, password)
  }

  return {
    loginUser
  }
}

export default useLogin
