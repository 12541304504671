import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import './_style/LocationSelect.css'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import useDebounce from 'myHooks/useDebounce'

const LocationSelect = ({ origin, setLocation }) => {
  const [value, setValue] = useState('')
  const debounced = useDebounce(value, 1000)
  const [loading, setLoading] = useState(false)
  const [options, setOptions] = useState([])

  const onChange = useCallback(e => setValue(e.target.value), [])

  useEffect(() => {
    if (value) setLoading(true)
  }, [value])

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const res = await fetch(`/.netlify/functions/fetchLocations?query=${debounced}`)
        const options = await res.json()
        setOptions(options)
      } catch (e) {
        setOptions([])
      }
      setLoading(false)
    }
    if (debounced) fetchOptions()
  }, [debounced, setOptions])

  const theme = createMuiTheme({
    overrides: {
      MuiFilledInput: {
        root: {
          backgroundColor: 'transparent !important'
        }
      }
    }
  })
  const onSelect = useCallback((_, value) => {
    setLocation(value)
  }, [setLocation])

  return (

    <ThemeProvider theme={theme}>
      <div className='volo-LocationSelect'>
        <div className='volo-LocationSelect-left'>
          <div className={`volo-LocationSelect-${origin ? 'from' : 'to'}`} />
          <div className='volo-LocationSelect-input-container'>
            <Autocomplete
              options={options}
              loading={loading}
              getOptionLabel={(option) => option.name}
              noOptionsText='No results'
              onChange={onSelect}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={origin ? 'Departure City' : 'Destination City'}
                  placeholder={origin ? 'Where are you flying from?' : 'Where are you flying to?'}
                  variant='filled'
                  onChange={onChange}
                />
              )}
            />
          </div>
        </div>
      </div>
    </ThemeProvider>
  )
}

LocationSelect.propTypes = {
  origin: PropTypes.bool.isRequired,
  setLocation: PropTypes.func.isRequired
}

export default LocationSelect
