import React, { useRef } from 'react'
import Proptypes from 'prop-types'
import useLogin from 'myHooks/useLogin'

import { adminScreens } from 'myUtils/constants'

const {
  COMPANIES
} = adminScreens

const Login = ({ setScreen }) => {
  const navigateToComanies = () => setScreen(COMPANIES)
  const { loginUser } = useLogin(navigateToComanies)
  const emailRef = useRef(null)
  const passwordRef = useRef(null)
  const login = () => {
    if (emailRef && passwordRef) {
      loginUser(
        emailRef.current.value,
        passwordRef.current.value
      )
    }
  }
  return (
    <div>
      <input ref={emailRef} type='email' />
      <input ref={passwordRef} type='password' />
      <button onClick={login}>Login</button>
    </div>

  )
}

Login.propTypes = {
  setScreen: Proptypes.func
}

export default Login
