import React from 'react'
import './header/_style/Header.css'

const Header = () => {
  const navigateTo = href => {
    window.location.href = href
  }
  return (
    <div className='volo-Header'>
      <div className='volo-Header-navigation'>
        <div className='volo-Header-logo' onClick={() => navigateTo('/')} />
        <div className='volo-Header-link' onClick={() => navigateTo('/')}>Find a Flight</div>
        <div className='volo-Header-link' onClick={() => navigateTo('/about')}>About</div>
      </div>
    </div>
  )
}

export default Header
