import React from 'react'
import Header from 'myPages/Header'
import './about/_style/About.css'

const About = () => (
  <div className='volo-About-navigation'>
    <Header />
    <div className='logo2' />
    <h1>About</h1>
    <div className='volo-About-about'>
      <ul className='volo-About-biography'>
        <li>
          <p>FlyVolo.co is a web based portal that connects private aviation clients to charter companies worldwide. It allows the end user to search flights through our specialized algorithmic program and return not only distance and time calculations, but process aircraft limitations, trip contingencies and even produces what we like to call “An 80% Quote”. Users can then select the participating charter company from a list based on this quote, and directly connect to their charter office.</p>
          <p><strong>It's that simple.</strong> No middlemen, no, “call me backs” - just a real live accurate quote with NO broker fees to pay.</p>
          <p>VOLO is free to use.  Forever.  Use it once, five times, or never and it costs the same – nothing.  In addition, it allows the end user to fully customize their own trips, including air carrier, car service, catering requests, and select high end hotels.  It doesn't get any more transparent than that.</p>
          <p>So what are you waiting for?  <strong><i>Lets fly today.</i></strong></p>
        </li>
        <li><div className='volo-About-volologo' />
        </li>
      </ul>
      <br />
      <h4>What We Are Not</h4>
      <ul>
        <li><h3>Not a broker</h3>FlyVolo.co is NOT a broker pretending to be a private jet company.</li>
        <li><h3>Not a sales company</h3>We do not charge a dime for the use of our portal.  You can map out trips as many times to as many places as you like, and when you're ready to go, you can book it yourself.  No hard sell here!</li>
        <li><h3>Not a charter company</h3>FlyVolo.co is NOT a charter company.  Instead, we connect you to the charter companies through our portal.  Why pay a broker 8-10% in fees just to dial a phone?  You should have control – not them.</li>
        <li><h3>Not a subscription</h3>FlyVolo.co does NOT charge annual fees, “JetCard” memberships, or anything of the like.  Travel private once in a lifetime, or once a week and the fees to use the portal are still ZERO.</li>
      </ul>
    </div>
  </div>
)

export default About
