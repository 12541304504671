import React, { useEffect } from 'react'
import Summary from 'myComponents/Summary'
import LogoDark from 'myAssets/images/logo-dark.svg'
import useConfirmation from 'myHooks/useConfirmation'
import './confirmation/_style/Confirmation.css'

const Confirmation = () => {
  const {
    getQuote,
    logo,
    trip,
    charterName,
    distance,
    duration,
    from,
    to,
    passengers,
    price,
    charterEmail,
    departDate
  } = useConfirmation()

  useEffect(() => {
    getQuote()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderTitle = () => {
    return 'Booking Confirmation'
  }

  const redirectTo = href => {
    window.location.href = href
  }

  return (
    <div className='volo-Confirmation'>
      <div className='volo-Confirmation-Header'>
        <img className='volo-Confirmation-Logo' alt='logo' src={LogoDark} />
        <div className='volo-Confirmation-Title'>{renderTitle()}</div>
      </div>
      <div className='volo-Confirmation-container'>
        <div className='volo-Confirmation-options'>
          <div className='volo-Confirmation-confirm-message'>All set! A representative from {charterName} will contact you shortly.</div>
          <div className='volo-Confirmation-title'>Quick Links</div>
          {/* <button className='volo-Confirmation-button'>Add to Your Calendar</button> */}
          {/* <button className='volo-Confirmation-button'>Share Itineary</button> */}
          <button onClick={() => redirectTo('/')} className='volo-Confirmation-button'>Book Another Flight</button>
          <div className='volo-Confirmation-title'>Get Support</div>
          <button onClick={() => redirectTo('mailto:contact@flyvolo.co')} className='volo-Confirmation-button'>Contact Volo</button>
          <button onClick={() => redirectTo(`mailto:${charterEmail}`)} className='volo-Confirmation-button'>Contact {charterName}</button>
        </div>
        <Summary
          trip={trip}
          duration={duration}
          price={price}
          charterName={charterName}
          distance={distance}
          from={from}
          to={to}
          logo={logo}
          passengers={passengers}
          departDate={departDate}
        />
      </div>
    </div>
  )
}

export default Confirmation
