import React from 'react'
import ApplePay from './ApplePay'
import Proptypes from 'prop-types'
// import Paypal from 'myAssets/images/paypal.svg'
import './_style/Payment.css'

const Payment = ({ mountApplePay, saveQuote, enabled }) => {
  const currentStyle = enabled ? {} : { pointerEvents: 'none', backgroundColor: 'var(--color-gray)', borderRadius: 52 }
  return (
    <div className='volo-Payment'>
      <button onClick={saveQuote} style={currentStyle} className='volo-Payment-creditcard'>Book with Credit Card</button>
      <ApplePay mountApplePay={mountApplePay} style={currentStyle} />
      {/* <button onClick={saveQuote} className='volo-Payment-paypal'>Book with
        <img alt='paypal' src={Paypal} className='volo-Payment-paypal-icon' />
      </button> */}
    </div>
  )
}

Payment.propTypes = {
  enabled: Proptypes.bool,
  saveQuote: Proptypes.func,
  mountApplePay: Proptypes.func
}

export default React.memo(Payment)
