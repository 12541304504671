import queryString from 'query-string'

async function apiError (errObj, errType) {
  if (errType === 'responseError') {
    const response = errObj
    let errMsg
    try {
      const error = await response.json()
      errMsg = error && error.errMsg
    } catch (err) {}
    return new Error(errMsg || `API request failed (${response.statusText})`)
  }
  throw new Error(errObj.message || 'API request failed')
}

async function post (url, body) {
  try {
    const token = localStorage.getItem('adminToken')

    const headers = new Headers({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      authorization: `Bearer ${token}`
    })
    const postData = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(body)
    }
    const response = await fetch(url, postData)
    if (!response.ok) {
      throw await apiError(response, 'responseError')
    }

    const respBody = await response.json()
    return respBody
  } catch (err) {
    throw await apiError(err)
  }
}

async function get (url, params) {
  try {
    if (params) {
      url = `${url}?${queryString.stringify(params)}`
    }
    const token = localStorage.getItem('adminToken')

    const headers = new Headers({
      authorization: `Bearer ${token}`
    })
    const getData = {
      method: 'GET',
      headers: headers
    }
    const response = await fetch(url, getData)
    if (!response.ok) {
      throw await apiError(response, 'responseError')
    }

    const respBody = await response.json()
    return respBody
  } catch (err) {
    throw await apiError(err)
  }
}

const api = {
  async saveQuote (quote) {
    const res = await post('/.netlify/functions/saveQuote', { ...quote })
    return res
  },

  async addCompany (company) {
    const res = await post('/.netlify/functions/addCompany', { ...company })
    return res
  },

  async deleteCompany (id) {
    const res = await post('/.netlify/functions/deleteCompany', { id })
    return res
  },

  async getCompanies () {
    const res = await get('/.netlify/functions/getCompanies')
    return res
  }
}

export default api
