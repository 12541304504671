import React, { useState, useRef, useEffect } from 'react'
import Proptypes from 'prop-types'

const ApplePay = ({ mountApplePay, style }) => {
  const [isMounted, setIsMounted] = useState(false)
  const applePayRef = useRef(null)
  useEffect(() => {
    if (applePayRef && applePayRef.current && !isMounted) {
      mountApplePay(applePayRef.current)
      setIsMounted(true)
    }
  }, [applePayRef, isMounted, mountApplePay])

  return (
    <div ref={applePayRef} style={style} className='volo-Payment-ApplePay' />
  )
}

ApplePay.propTypes = {
  mountApplePay: Proptypes.func,
  style: Proptypes.object
}

export default React.memo(ApplePay)
