import React, { useMemo, useCallback } from 'react'
import ErrorDialog from 'myComponents/ErrorDialog'
import LogoDark from 'myAssets/images/logo-dark.svg'
import SelectCharter from './quote/SelectCharter'
import CharterDetails from './quote/CharterDetails'
import PassengerDetails from './quote/PassengerDetails'
import Summary from 'myComponents/Summary'
import useQuote from 'myHooks/useQuote'
import './quote/_style/Quote.css'

import { stages } from 'myUtils/constants'

const fullWidth = { gridColumn: '1/3' }

const {
  SELECT_CHARTER,
  REVIEW_QUOTE,
  PASSENGER_DETAILS
} = stages

const Quote = () => {
  const {
    quotes,
    stage,
    nextStage,
    previousStage,
    selectedQuote,
    trip,
    chooseQuote,
    passengers,
    getQuotes,
    charterName,
    distance,
    duration,
    from,
    logo,
    price,
    pricePerPassenger,
    departDate,
    optional,
    setOptional,
    setPassengerDetails,
    to,
    firstName,
    lastName,
    email,
    error,
    dismissError,
    mountApplePay,
    phone,
    postCardPay,
    contactPreference
  } = useQuote()

  const renderTitle = useCallback(() => {
    if (stage === SELECT_CHARTER) return 'Select Charter'
    if (stage === REVIEW_QUOTE) return 'Quote'
    if (stage === PASSENGER_DETAILS) return 'Reserve Your Flight'
    return ''
  }, [stage])

  const navigateTo = useCallback(href => {
    window.location.href = href
  }, [])

  return useMemo(() => (
    <div className='volo-Quote'>
      {error && <ErrorDialog message='Please enter a valid email or phone number' dismissError={dismissError} />}
      <div className='volo-Quote-Header'>
        <img className='volo-Quote-Logo' alt='logo' onClick={() => navigateTo('/')} src={LogoDark} />
        <div className='volo-Quote-Title'>{renderTitle()}</div>
      </div>
      {stage === SELECT_CHARTER &&
        <SelectCharter
          quotes={quotes}
          setSelectedQuote={chooseQuote}
          getQuotes={getQuotes}
          selectedQuote={selectedQuote}
        />}
      {stage === REVIEW_QUOTE &&
        <CharterDetails
          duration={duration}
          from={from}
          to={to}
          price={price}
          pricePerPassenger={pricePerPassenger}
          distance={distance}
          charterName={charterName}
          logo={logo}
          passengers={passengers}
          departDate={departDate}
          optional={optional}
          setOptional={setOptional}
        />}
      {stage === PASSENGER_DETAILS &&
        <Summary
          duration={duration}
          from={from}
          to={to}
          price={price}
          pricePerPassenger={pricePerPassenger}
          distance={distance}
          charterName={charterName}
          optional={optional}
          trip={trip}
          logo={logo}
          passengers={passengers}
          departDate={departDate}
        />}
      {stage === PASSENGER_DETAILS &&
        <PassengerDetails
          setPassengerDetails={setPassengerDetails}
          firstName={firstName}
          lastName={lastName}
          email={email}
          phone={phone}
          mountApplePay={mountApplePay}
          contactPreference={contactPreference}
          postCardPay={postCardPay}
        />}
      <div className='volo-Quote-controls'>
        {stage !== SELECT_CHARTER &&
          <button
            style={stage === PASSENGER_DETAILS ? fullWidth : null}
            onClick={previousStage}
            className='volo-Quote-back'
          >
          Back
          </button>}
        {![PASSENGER_DETAILS, SELECT_CHARTER].includes(stage) &&
          <button
            disabled={selectedQuote === null}
            style={stage === SELECT_CHARTER ? fullWidth : null}
            onClick={nextStage}
            className={`volo-Quote-${selectedQuote === null ? 'back' : 'next'}`}
          >
            {selectedQuote === null ? 'Select Charter' : 'Next'}
          </button>}
      </div>
    </div>
  ), [charterName, chooseQuote, contactPreference, departDate, dismissError, distance, duration, email, error, firstName, from, getQuotes, lastName, logo, mountApplePay, navigateTo, nextStage, optional, passengers, phone, postCardPay, previousStage, price, pricePerPassenger, quotes, renderTitle, selectedQuote, setOptional, setPassengerDetails, stage, to, trip])
}

export default React.memo(Quote)
